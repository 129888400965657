<template>
  <div class="flex-grow-1 w-100 d-flex align-items-center">
    <div class="bg-composed-wrapper--content py-5">
      <div class="container">
        <div>
          <div
            class="
            "
          >
            <b-card
              class="text-black mt-3 my-4"
              style="box-shadow: none !important;"
            >
              <!-- <LogoSvg class="w-50 mx-auto d-block img-fluid mb-3" /> -->

              <h1
                class="display-3 mb-3 text-primary font-weight-bold text-center"
                style="color: #07122A !important; font-size: 1.3rem;"
              >
                แจ้งเบาะแสการกระทำผิด
              </h1>
              <p class="text-center" style="font-size: 0.8rem;">
                <b>
                  บช.ปส.
                  ขอสงวนสิทธิ์ในการตรวจสอบและพิจารณาดำเนินการตามข้อมูลที่มีการแจ้งเบาะแส
                  โดยจะมีแนวทางพิจารณาดำเนินการ
                  ในข้อมูลที่มีความน่าเชื่อถือมากที่สุดตามลำดับ
                  ทั้งในส่วนของผู้แจ้งและผู้ที่ถูกแจ้งเบาะแสประกอบกัน
                  โดยข้อมูลของผู้แจ้งเบาะแสจะปิดเป็นความลับ
                </b>
              </p>

              <p class="text-center" style="font-size: 0.8rem;">
                กรุณากรอกข้อมูลแจ้งเบาะแสยาเสพติดตามความเป็นจริงและละเอียดที่สุด<br />เพื่อให้เจ้าหน้าที่นำข้อมูลของท่านไปทำงานได้จริง
                และรวดเร็วที่สุด
              </p>
              <img
                src="@/assets/img/step1.png"
                class="img-fluid d-none d-lg-flex"
                v-if="step == 1"
              />
              <img
                src="@/assets/img/step2.png"
                class="img-fluid d-none d-lg-flex"
                v-if="step == 2 || step == 3 || step == 4"
              />
              <img
                src="@/assets/img/step3.png"
                class="img-fluid d-none d-lg-flex"
                v-if="step == 5"
              />
              <img
                src="@/assets/img/step4.png"
                class="img-fluid d-none d-lg-flex"
                v-if="step == 6"
              />
              <br />
              <div
                style="border: 1px solid #13537F;
              background: #13537F;
              border-top-left-radius: 20px;
              border-top-right-radius: 20px;"
                v-if="step == 1"
              >
                <p
                  style="padding-left: 20px; color: #fff; font-size: 1.2rem;
                          padding-top: 20px;"
                >
                  <b>ประเภทข้อมูล <span style="color: #f00;">*</span></b>
                </p>
              </div>
              <div
                style="border: 1px solid #13537F;
              background: #13537F;
              border-top-left-radius: 20px;
              border-top-right-radius: 20px;"
                v-if="step == 2"
              >
                <p
                  style="padding-left: 20px; color: #fff; font-size: 1.2rem;
                          padding-top: 20px;"
                >
                  <b>สถานที่กระทำผิด (สถานที่ขายหรือค้า/สถานที่มั่วสุม) <span style="color: #f00;">*</span></b>
                </p>
              </div>
              <div
                style="border: 1px solid #13537F;
              background: #13537F;
              border-top-left-radius: 20px;
              border-top-right-radius: 20px;"
                v-if="step == 3"
              >
                <p
                  style="padding-left: 20px; color: #fff; font-size: 1.2rem;
                          padding-top: 20px;"
                >
                  <b
                    >ข้อมูลผู้กระทำความผิด หรือ ผู้ที่ต้องการให้นำตัวไปบำบัด
                    <span style="color: #f00;">*</span></b
                  >
                </p>
              </div>
              <div
                style="border: 1px solid #13537F;
              background: #13537F;
              border-top-left-radius: 20px;
              border-top-right-radius: 20px;"
                v-if="step == 4"
              >
                <p
                  style="padding-left: 20px; color: #fff; font-size: 1.2rem;
                          padding-top: 20px;"
                >
                  <b>ข้อมูลยาเสพติด</b>
                </p>
              </div>
              <div
                style="border: 1px solid #13537F;
              background: #13537F;
              border-top-left-radius: 20px;
              border-top-right-radius: 20px;"
                v-if="step == 5"
              >
                <p
                  style="padding-left: 20px; color: #fff; font-size: 1.2rem;
                          padding-top: 20px;"
                >
                  <b>ภาพถ่าย (ถ้ามี)</b>
                </p>
              </div>
              <div
                style="border: 1px solid #13537F;
              background: #13537F;
              border-top-left-radius: 20px;
              border-top-right-radius: 20px;"
                v-if="step == 6"
              >
                <p
                  style="padding-left: 20px; color: #fff; font-size: 1.2rem;
                          padding-top: 20px;"
                >
                  <b>ข้อมูลผู้แจ้ง (กรณีประสงค์จะให้ข้อมูล)</b>
                </p>
              </div>

              <div
                style="background: #EEEEEE;
              border-bottom-left-radius: 20px;
              border-bottom-right-radius: 20px;"
              >
                <form style="padding: 20px;" v-if="step == 1">
                  <b-row>
                    <b-col>
                      <div class="form-group">
                        <label style="color: #13537F; font-size: 1.2rem;"
                          >เลือกประเภทข้อมูล</label
                        >
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" lg="3">
                      <!-- <div class="form-group"> -->
                      <!-- <label for="tel">จังหวัด</label> -->
                      <b-form-checkbox @change="onClickType('ผู้ผลิตยาเสพติด')">
                        ผู้ผลิตยาเสพติด
                      </b-form-checkbox>
                    </b-col>
                    <b-col cols="12" lg="3">
                      <b-form-checkbox @change="onClickType('ผู้ค้ารายย่อย')">
                        ผู้ค้ารายย่อย
                      </b-form-checkbox>
                    </b-col>
                    <b-col cols="12" lg="3">
                      <b-form-checkbox @change="onClickType('ผู้เสพยาเสพติด')">
                        ผู้เสพยาเสพติด
                      </b-form-checkbox>
                    </b-col>
                    <b-col cols="12" lg="3">
                      <b-form-checkbox
                        @change="onClickType('ผู้ขายและเสพยาเสพติด')"
                      >
                        ผู้ขายและเสพยาเสพติด
                      </b-form-checkbox>
                    </b-col>
                    <b-col cols="12" lg="3">
                      <b-form-checkbox @change="onClickType('แหล่งจำหน่าย')">
                        แหล่งจำหน่าย
                      </b-form-checkbox>
                    </b-col>
                    <b-col cols="12" lg="3">
                      <b-form-checkbox @change="onClickType('แหล่งมั่วสุม')">
                        แหล่งมั่วสุม
                      </b-form-checkbox>
                    </b-col>
                    <b-col cols="12" lg="3">
                      <b-form-checkbox @change="onClickType('ผู้ค้ารายใหญ่')">
                        ผู้ค้ารายใหญ่
                      </b-form-checkbox>
                    </b-col>
                    <b-col cols="12" lg="3">
                      <b-form-checkbox @change="onClickType('ผู้ค้ารายใหญ่')">
                        ตามหมายจับ
                      </b-form-checkbox>
                    </b-col>
                    <b-col cols="12" lg="3">
                      <b-form-checkbox @change="onClickType('อื่นๆ โปรดระบุ')">
                        อื่นๆ โปรดระบุ
                      </b-form-checkbox>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="12"
                      v-if="
                        reportInformation.report_type.includes('อื่นๆ โปรดระบุ')
                      "
                    >
                      <br />
                      <input
                        type="text"
                        class="form-control"
                        placeholder="ระบุประเภทข้อมูลที่แจ้ง"
                        v-model="reportInformation.report_type_other"
                      />
                    </b-col>
                  </b-row>
                  <br />
                </form>
                <div style="padding: 20px;" v-if="step == 2">
                  <div
                    v-for="(location, index) in locations"
                    :key="`location_${index}`"
                  >
                    <b-row>
                      <b-col>
                        <div class="form-group">
                          <label style="color: #13537F; font-size: 1.2rem;"
                            >สถานที่ ลำดับที่ {{ index + 1 }}</label
                          >
                        </div>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="12" lg="4">
                        <div class="form-group">
                          <label for="tel">สถานที่ <span style="color: #f00;">*</span></label>
                          <v-select
                            id="termFrom"
                            style="background: #fff;"
                            v-model="location.location_type"
                            :options="locatinos"
                            item-text="locatinos"
                          ></v-select>
                        </div>
                      </b-col>
                      <b-col cols="12" lg="8">
                        <div class="form-group">
                          <label for="tel">ชื่อสถานที่ <span style="color: #f00;">*</span></label>
                          <input
                            type="text"
                            class="form-control"
                            name="tel"
                            v-model="location.location_name"
                          />
                        </div>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="12" lg="3">
                        <div class="form-group">
                          <label for="tel">จังหวัด <span style="color: #f00;">*</span></label>
                          <v-select
                            style="background: #fff;"
                            v-model="location.location_province"
                            :options="provinces"
                            label="name_in_thai"
                            @input="onChangeProvince"
                          ></v-select>
                        </div>
                      </b-col>
                      <b-col cols="12" lg="3">
                        <div class="form-group">
                          <label for="tel">เขต / อำเภอ <span style="color: #f00;">*</span></label>
                          <v-select
                            id="termFrom"
                            style="background: #fff;"
                            v-model="location.location_district"
                            :options="districts"
                            @input="onChangeDistrict"
                            label="name_in_thai"
                          ></v-select>
                        </div>
                      </b-col>
                      <b-col cols="12" lg="3">
                        <div class="form-group">
                          <label for="tel">แขวง / ตำบล <span style="color: #f00;">*</span></label>
                          <v-select
                            id="termFrom"
                            style="background: #fff;"
                            v-model="location.location_sub_district"
                            :options="subDistricts"
                            @input="onChangeSubDistrict($event, index)"
                            label="name_in_thai"
                          ></v-select>
                        </div>
                      </b-col>
                      <b-col cols="12" lg="3">
                        <div class="form-group">
                          <label for="tel">หมู่บ้าน <span style="color: #f00;">*</span></label>
                          <input
                            type="text"
                            class="form-control"
                            name="tel"
                            v-model="location.location_village"
                          />
                        </div>
                      </b-col>
                      <b-col cols="12" lg="12">
                        <label for="tel">เลือกตำแหน่ง</label>
                        <GmapMap
                          :center="location.center"
                          :zoom="18"
                          map-style-id="roadmap"
                          :options="mapOptions"
                          style="width: 100%; height: 50vmin"
                          ref="mapRef"
                          @click="handleMapClick($event, index)"
                        >
                          <GmapMarker
                            :position="location.marker.position"
                            :clickable="true"
                            :draggable="true"
                            @drag="handleMarkerDrag($event, index)"
                            @click="panToMarker"
                          />
                        </GmapMap>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col>
                        <v-divider></v-divider>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="text-right">
                        <button
                          class="btn btn-xs btn-whitebtn form-group"
                          style="border: 1px solid #000; margin-right: 10px;"
                          @click="onClickDeleteLocation(index)"
                          v-if="index != 0"
                        >
                          ลบสถานที่
                        </button>
                        <button
                          class="btn btn-xs btn-whitebtn form-group"
                          style="border: 1px solid #000;"
                          @click="onClickAddLocation()"
                        >
                          เพิ่มสถานที่
                        </button>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <div style="padding: 20px;" v-if="step == 3">
                  <div
                    v-for="(suspect, index) in suspects"
                    :key="`suspect_${index}`"
                  >
                    <b-row>
                      <b-col>
                        <div class="form-group">
                          <label style="color: #13537F; font-size: 1.3rem;"
                            >ผู้ต้องสงสัย ลำดับที่ {{ index + 1 }}</label
                          >
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" md="12">
                        <div class="form-group">
                          <label style="color: #13537F; font-size: 1.2rem;"
                            >ข้อมูลผู้ต้องสงสัย</label
                          >
                        </div>
                      </b-col>
                      <b-col cols="12" md="3">
                        <div class="form-group">
                          <label for="tel">ชื่อ</label>
                          <input
                            type="text"
                            class="form-control"
                            name="tel"
                            v-model="suspect.suspect_name"
                          />
                        </div>
                      </b-col>
                      <b-col cols="12" md="3">
                        <div class="form-group">
                          <label for="tel">นามสกุล</label>
                          <input
                            type="text"
                            class="form-control"
                            name="tel"
                            v-model="suspect.suspect_lastname"
                          />
                        </div>
                      </b-col>
                      <b-col cols="12" md="3">
                        <div class="form-group">
                          <label for="tel">ฉายา <span style="color: #f00;">*</span></label>
                          <input
                            type="text"
                            class="form-control"
                            name="tel"
                            v-model="suspect.suspect_nickname"
                          />
                        </div>
                      </b-col>
                      <b-col cols="12" md="3">
                        <div class="form-group">
                          <label for="tel">อายุประมาณ</label>
                          <input
                            type="number"
                            class="form-control"
                            name="tel"
                            v-mask="'##'"
                            v-model="suspect.suspect_age"
                          />
                        </div>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="12" md="3">
                        <div class="form-group">
                          <label for="tel">เพศ</label>
                          <v-select
                            id="termFrom"
                            style="background: #fff;"
                            v-model="suspect.suspect_sex"
                            :options="sex"
                          ></v-select>
                        </div>
                      </b-col>
                      <b-col cols="12" md="3">
                        <div class="form-group">
                          <label for="tel">เจ้าหน้าที่ของรัฐ</label>
                          <v-select
                            id="termFrom"
                            style="background: #fff;"
                            v-model="suspect.suspect_is_grov"
                            :options="grovs"
                          ></v-select>
                        </div>
                      </b-col>
                      <b-col cols="12" md="3">
                        <div class="form-group">
                          <label for="tel">ส่วนสูงประมาณ</label>
                          <input
                            type="number"
                            class="form-control"
                            name="tel"
                            v-mask="'###'"
                            v-model="suspect.suspect_height"
                          />
                        </div>
                      </b-col>
                      <b-col cols="12" md="3">
                        <div class="form-group">
                          <label for="tel">สิผิว</label>
                          <v-select
                            id="termFrom"
                            style="background: #fff;"
                            v-model="suspect.suspect_skin"
                            :options="skins"
                          ></v-select>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" md="12">
                        <div class="form-group">
                          <label for="tel"
                            >รอยสัก/แผลเป็น/ความพิการ/ลักษณะเด่น</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            v-model="suspect.suspect_tattoo"
                          />
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" md="6">
                        <div class="form-group">
                          <label for="tel">อาชีพ</label>
                          <v-select
                            id="termFrom"
                            style="background: #fff;"
                            v-model="suspect.suspect_job"
                            :options="jobs"
                          ></v-select>
                        </div>
                      </b-col>
                      <b-col cols="12" md="6">
                        <div class="form-group">
                          <label for="tel">หน่วยงาน/บริษัท</label>

                          <input
                            type="text"
                            class="form-control"
                            v-model="suspect.suspect_company"
                          />
                        </div>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="12" md="12">
                        <div class="form-group">
                          <br />
                          <label style="color: #13537F; font-size: 1.2rem;"
                            >ที่พักอาศัยปัจจุบัน</label
                          >
                        </div>
                      </b-col>
                      <b-col cols="12" md="12">
                        <b-form-checkbox @change="sameLocation(index)">
                          ที่อยู่เดียวกับสถานที่กระทำผิด
                        </b-form-checkbox>
                        <br />
                      </b-col>
                      <b-col cols="12" md="12">
                        <div class="form-group">
                          <label for="tel">ชื่อสถานที่</label>

                          <input
                            type="text"
                            class="form-control"
                            v-model="suspect.suspect_address_name"
                          />
                        </div>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="12" lg="3">
                        <div class="form-group">
                          <label for="tel">จังหวัด <span style="color: #f00;">*</span></label>
                          <v-select
                            id="termFrom"
                            style="background: #fff;"
                            v-model="suspect.suspect_province"
                            :options="provinces"
                            @input="onChangeProvince"
                            label="name_in_thai"
                          ></v-select>
                        </div>
                      </b-col>
                      <b-col cols="12" lg="3">
                        <div class="form-group">
                          <label for="tel">เขต / อำเภอ <span style="color: #f00;">*</span></label>
                          <v-select
                            id="termFrom"
                            style="background: #fff;"
                            v-model="suspect.suspect_district"
                            :options="districts"
                            @input="onChangeDistrict"
                            label="name_in_thai"
                          ></v-select>
                        </div>
                      </b-col>
                      <b-col cols="12" lg="3">
                        <div class="form-group">
                          <label for="tel">แขวง / ตำบล <span style="color: #f00;">*</span></label>
                          <v-select
                            id="termFrom"
                            style="background: #fff;"
                            v-model="suspect.suspect_sub_district"
                            :options="subDistricts"
                            label="name_in_thai"
                          ></v-select>
                        </div>
                      </b-col>
                      <b-col cols="12" lg="3">
                        <div class="form-group">
                          <label for="tel">หมู่บ้าน <span style="color: #f00;">*</span></label>
                          <input
                            type="text"
                            class="form-control"
                            name="tel"
                            v-model="suspect.suspect_village"
                          />
                        </div>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="12" md="12">
                        <b-form-checkbox v-model="suspect.suspect_is_in_area">
                          เป็นบุคคลในพื้นที่หรือไม่
                        </b-form-checkbox>
                        <br />
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col>
                        <div class="form-group">
                          <label for="tel">รายละเอียด</label>
                          <textarea
                            class="form-control"
                            v-model="suspect.suspect_detail"
                            placeholder="ระบุรายละเอียดการกระทำผิด ลักษณะที่อยู่อาศัย เช่น บ้านปูน/บ้านไม้ กี่ชั้น สีบ้าน จุดสังเกต ช่วงเวลากระทำผิด ราคายา แหล่งซื้อ จุดซุกซ่อน อาวุธปืน ยานพาหนะ เบอร์โทรศัพท์ LINE Facebook Social ต่าง ๆ"
                            rows="3"
                          />
                        </div>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="12" md="12">
                        <div class="form-group">
                          <br />
                          <label style="color: #13537F; font-size: 1.2rem;"
                            >ข้อมูล Social ของผู้ต้องสงสัย</label
                          >
                        </div>
                      </b-col>
                      <b-col cols="12" md="4">
                        <div class="form-group">
                          <label for="tel">Facebook</label>

                          <input
                            type="text"
                            class="form-control"
                            v-model="suspect.suspect_facebook"
                          />
                        </div>
                      </b-col>
                      <b-col cols="12" md="4">
                        <div class="form-group">
                          <label for="tel">Instagram</label>

                          <input
                            type="text"
                            class="form-control"
                            v-model="suspect.suspect_ig"
                          />
                        </div>
                      </b-col>
                      <b-col cols="12" md="4">
                        <div class="form-group">
                          <label for="tel">Line ID</label>

                          <input
                            type="text"
                            class="form-control"
                            v-model="suspect.suspect_line"
                          />
                        </div>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="12" md="4">
                        <div class="form-group">
                          <label for="tel">Twitter</label>

                          <input
                            type="text"
                            class="form-control"
                            v-model="suspect.suspect_twitter"
                          />
                        </div>
                      </b-col>
                      <b-col cols="12" md="4">
                        <div class="form-group">
                          <label for="tel">Tiktok</label>

                          <input
                            type="text"
                            class="form-control"
                            v-model="suspect.suspect_tiktok"
                          />
                        </div>
                      </b-col>
                      <b-col cols="12" md="4">
                        <div class="form-group">
                          <label for="tel">อื่นๆ โปรดระบุ</label>

                          <input
                            type="text"
                            class="form-control"
                            v-model="suspect.suspect_social_other"
                          />
                        </div>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="12" md="12">
                        <div class="form-group">
                          <br />
                          <label style="color: #13537F; font-size: 1.2rem;"
                            >ข้อมูลเบอร์โทรและอีเมลของผู้ต้องสงสัย</label
                          >
                        </div>
                      </b-col>
                    </b-row>
                    <b-row
                      v-for="(suspectContact,
                      contactIndex) of suspect.suspectContacts"
                      :key="`suspectcontact_${contactIndex}`"
                    >
                      <b-col cols="12" md="6">
                        <div class="form-group">
                          <label for="tel">Email</label>

                          <input
                            type="text"
                            class="form-control"
                            v-model="suspectContact.suspect_email"
                          />
                        </div>
                      </b-col>
                      <b-col cols="12" md="6">
                        <div class="form-group">
                          <label for="tel">เบอร์โทรศัพท์</label>

                          <input
                            type="tel"
                            class="form-control"
                            name="tel"
                            placeholder="0xx-xxx-xxxx"
                            v-mask="'0##-###-####'"
                            v-model="suspect.suspect_tel"
                          />
                        </div>
                      </b-col>
                      <b-col class="text-right">
                        <button
                          class="btn btn-xs btn-whitebtn form-group"
                          style="border: 1px solid #000; margin-right: 10px;"
                          @click="onClickDeleteContact(index, contactIndex)"
                          v-if="contactIndex != 0"
                        >
                          ลบ
                        </button>
                        <button
                          class="btn btn-xs btn-whitebtn form-group"
                          style="border: 1px solid #000;"
                          @click="onClickAddContact(index)"
                        >
                          เพิ่ม
                        </button>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" md="12">
                        <div class="form-group">
                          <br />
                          <label style="color: #13537F; font-size: 1.2rem;"
                            >ข้อมูลบัตรประชาชน
                            ข้อมูลบัญชีหรือเลขบัตรเครดิตของผู้ต้องสงสัย</label
                          >
                        </div>
                      </b-col>
                      <b-col cols="12" md="12">
                        <div class="form-group">
                          <label for="tel">หมายบัตรประชาชนผู้ต้องสงสัย</label>

                          <input
                            type="tel"
                            class="form-control"
                            v-mask="'#############'"
                            v-model="suspect.suspect_id_card"
                          />
                        </div>
                      </b-col>
                    </b-row>

                    <b-row
                      v-for="(suspectCard, cardIndex) of suspect.suspectCards"
                      :key="`suspectcard_${cardIndex}`"
                    >
                      <b-col cols="12" md="6">
                        <div class="form-group">
                          <label for="tel"
                            >หมายเลขบัญชี หมายเลขบัตรเครดิต หรือบัตรเดบิต</label
                          >

                          <input
                            type="tel"
                            class="form-control"
                            v-model="suspectCard.suspect_card_no"
                          />
                        </div>
                      </b-col>
                      <b-col cols="12" md="6">
                        <div class="form-group">
                          <label for="tel"
                            >ธนาคารหรือสถาบันการเงินของหมายเลขบัตรเครดิต
                            หรือบัตรเดบิต</label
                          >

                          <v-select
                            id="termFrom"
                            style="background: #fff;"
                            v-model="suspectCard.suspect_card_bank"
                            :options="banks"
                          ></v-select>
                        </div>
                      </b-col>
                      <b-col class="text-right">
                        <button
                          class="btn btn-xs btn-whitebtn form-group"
                          style="border: 1px solid #000; margin-right: 10px;"
                          @click="onClickDeleteCard(index, cardIndex)"
                          v-if="cardIndex != 0"
                        >
                          ลบ
                        </button>
                        <button
                          class="btn btn-xs btn-whitebtn form-group"
                          style="border: 1px solid #000;"
                          @click="onClickAddCard(index)"
                        >
                          เพิ่ม
                        </button>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="12" md="12">
                        <div class="form-group">
                          <br />
                          <label style="color: #13537F; font-size: 1.2rem;"
                            >ข้อมูลยานพาหนะของผู้ต้องสงสัย</label
                          >
                        </div>
                      </b-col>
                    </b-row>

                    <b-row
                      v-for="(suspectTransport,
                      transportIndex) of suspect.suspectTransports"
                      :key="`suspecttransport_${transportIndex}`"
                    >
                      <b-col cols="12" md="4">
                        <div class="form-group">
                          <label for="tel">ประเภทรถ</label>

                          <v-select
                            id="termFrom"
                            style="background: #fff;"
                            v-model="suspectTransport.transport_type"
                            :options="carTypes"
                          ></v-select>
                        </div>
                      </b-col>
                      <b-col cols="12" md="4">
                        <div class="form-group">
                          <label for="tel">ยี่ห้อรถ</label>

                          <v-select
                            id="termFrom"
                            style="background: #fff;"
                            v-model="suspectTransport.transport_brand"
                            :options="carBrands"
                          ></v-select>
                        </div>
                      </b-col>
                      <b-col cols="12" md="4">
                        <div class="form-group">
                          <label for="tel">สีรถ</label>

                          <v-select
                            id="termFrom"
                            style="background: #fff;"
                            v-model="suspectTransport.transport_color"
                            :options="carColors"
                          ></v-select>
                        </div>
                      </b-col>
                      <b-col cols="12" md="6">
                        <div class="form-group">
                          <label for="tel">หมายเลขทะเบียน</label>

                          <input
                            type="text"
                            class="form-control"
                            v-model="suspectTransport.transport_license_no"
                          />
                        </div>
                      </b-col>
                      <b-col cols="12" md="6">
                        <div class="form-group">
                          <label for="tel">จังหวัด</label>
                          <v-select
                            id="termFrom"
                            style="background: #fff;"
                            v-model="
                              suspectTransport.transport_license_province
                            "
                            :options="provinces"
                            label="name_in_thai"
                          ></v-select>
                        </div>
                      </b-col>
                      <b-col class="text-right">
                        <button
                          class="btn btn-xs btn-whitebtn form-group"
                          style="border: 1px solid #000; margin-right: 10px;"
                          @click="onClickDeleteTransport(index, transportIndex)"
                          v-if="transportIndex != 0"
                        >
                          ลบ
                        </button>
                        <button
                          class="btn btn-xs btn-whitebtn form-group"
                          style="border: 1px solid #000;"
                          @click="onClickAddTransport(index)"
                        >
                          เพิ่ม
                        </button>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <v-divider></v-divider>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="text-right">
                        <button
                          class="btn btn-xs btn-whitebtn form-group"
                          style="border: 1px solid #000; margin-right: 10px;"
                          @click="onClickDeleteSuspect(index)"
                          v-if="index != 0"
                        >
                          ลบข้อมูลผู้ต้องสงสัย
                        </button>
                        <button
                          class="btn btn-xs btn-whitebtn form-group"
                          style="border: 1px solid #000;"
                          @click="onClickAddSuspect()"
                        >
                          เพิ่มข้อมูลผู้ต้องสงสัย
                        </button>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <div
                  style="background: #EEEEEE;
              border-bottom-left-radius: 20px;
              border-bottom-right-radius: 20px;"
                >
                  <form style="padding: 20px;" v-if="step == 4">
                    <b-row>
                      <b-col>
                        <div class="form-group">
                          <label style="color: #13537F; font-size: 1.2rem;"
                            >เลือกข้อมูลยาเสพติด (ระบุได้มากกว่า 1 ชนิด)</label
                          >
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="6" lg="3">
                        <!-- <div class="form-group"> -->
                        <!-- <label for="tel">จังหวัด</label> -->
                        <b-form-checkbox @change="onClickDrug('ยาบ้า')">
                          ยาบ้า
                        </b-form-checkbox>
                      </b-col>
                      <b-col cols="6" lg="3">
                        <b-form-checkbox @change="onClickDrug('ไอซ์')">
                          ไอซ์
                        </b-form-checkbox>
                      </b-col>
                      <b-col cols="6" lg="3">
                        <b-form-checkbox @change="onClickDrug('ยาอี')">
                          ยาอี
                        </b-form-checkbox>
                      </b-col>
                      <b-col cols="6" lg="3">
                        <b-form-checkbox @change="onClickDrug('กัญชา')">
                          กัญชา
                        </b-form-checkbox>
                      </b-col>
                      <b-col cols="6" lg="3">
                        <b-form-checkbox @change="onClickDrug('ยาเค')">
                          ยาเค
                        </b-form-checkbox>
                      </b-col>
                      <b-col cols="6" lg="3">
                        <b-form-checkbox @change="onClickDrug('กระท่อม')">
                          กระท่อม
                        </b-form-checkbox>
                      </b-col>
                      <b-col cols="6" lg="3">
                        <b-form-checkbox @change="onClickDrug('อื่นๆ')">
                          อื่นๆ โปรดระบุ
                        </b-form-checkbox>
                      </b-col>
                      <b-col cols="12" lg="6" v-if="isShowDrugOther">
                        <br />
                        <input
                          type="text"
                          class="form-control"
                          placeholder="โปรดระบุ"
                          v-model="reportInformation.report_drug_other"
                        />
                      </b-col>
                    </b-row>
                  </form>
                </div>
                <form style="padding: 20px;" v-if="step == 5">
                  <b-row>
                    <b-col>
                      <div class="form-group">
                        <label style="color: #13537F; font-size: 1.3rem;"
                          >รูปถ่าย</label
                        >
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="col-12 px-2">
                        <label class="font-weight-bold ft-s-14" for="bannerImg"
                          >อัพโหลดไฟล์</label
                        >

                        <b-button
                          size="sm"
                          variant="primary"
                          class="mx-2"
                          @click="toggleAttachFile"
                        >
                          เลือกไฟล์
                        </b-button>

                        <small class="text-black-50 ml-2 font-weight-bold"
                          ><span style="color: #f00;">*</span>JPG, PNG ขนาดไม่เกิน 10MB
                        </small>

                        <input
                          v-show="false"
                          type="file"
                          id="bannerImg"
                          name="bannerImg"
                          accept="image/*"
                          ref="bannerImg"
                          @change="previewImage"
                        />
                        <br />
                        <br />
                        <div>
                          <div
                            class="d-flex flex-row flex-wrap"
                            v-for="(file,
                            index) in reportInformation.attachedFiles"
                            :key="`lesson-${index}`"
                          >
                            <div class="col-lg-11 col-12 px-2 mb-2">
                              <!-- <input class="form-control custom__height" type="text" /> -->
                              <p>{{ file.name }}</p>
                            </div>
                            <div
                              class="d-block col-lg-1 col-12 mb-2 pb-1 text-right"
                              style="margin: auto"
                            >
                              <font-awesome-icon
                                icon="window-close"
                                class="fa-lg cursor-pointer"
                                @click.prevent="deleteAttached(index)"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </form>
                <form style="padding: 20px;" v-if="step == 6">
                  <!-- <b-row>
                    <b-col>
                      <div class="form-group">
                        <label style="color: #13537F; font-size: 1.3rem;"
                          >ข้อมูลผู้แจ้ง (กรณีประสงค์จะให้ข้อมูล)</label
                        >
                      </div>
                    </b-col>
                  </b-row> -->
                  <b-row>
                    <b-col cols="12" md="4">
                      <div class="form-group">
                        <label for="tel">ชื่อ</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="reportInformation.reporter_firstname"
                        />
                      </div>
                    </b-col>
                    <b-col cols="12" md="4">
                      <div class="form-group">
                        <label for="tel">นามสกุล</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="reportInformation.reporter_lastname"
                        />
                      </div>
                    </b-col>
                    <b-col cols="12" md="4">
                      <div class="form-group">
                        <label for="tel">เพศ</label>
                        <v-select
                          id="termFrom"
                          style="background: #fff;"
                          v-model="reportInformation.reporter_sex"
                          :options="sex"
                        ></v-select>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" md="6">
                      <div class="form-group">
                        <label for="tel">บ้านเลขที่</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="reportInformation.reporter_address"
                        />
                      </div>
                    </b-col>
                    <b-col cols="12" md="6">
                      <div class="form-group">
                        <label for="tel">โทรศัพท์</label>
                        <input
                          type="tel"
                          class="form-control"
                          name="tel"
                          placeholder="0xx-xxx-xxxx"
                          v-mask="'0##-###-####'"
                          v-model="reportInformation.reporter_tel"
                        />
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" lg="3">
                      <div class="form-group">
                        <label for="tel">จังหวัด</label>
                        <v-select
                          id="termFrom"
                          style="background: #fff;"
                          v-model="reportInformation.reporter_province"
                          :options="provinces"
                          @input="onChangeProvince"
                          label="name_in_thai"
                        ></v-select>
                      </div>
                    </b-col>
                    <b-col cols="12" lg="3">
                      <div class="form-group">
                        <label for="tel">เขต / อำเภอ</label>
                        <v-select
                          id="termFrom"
                          style="background: #fff;"
                          v-model="reportInformation.reporter_district"
                          :options="districts"
                          @input="onChangeDistrict"
                          label="name_in_thai"
                        ></v-select>
                      </div>
                    </b-col>
                    <b-col cols="12" lg="3">
                      <div class="form-group">
                        <label for="tel">แขวง / ตำบล</label>
                        <v-select
                          id="termFrom"
                          style="background: #fff;"
                          v-model="reportInformation.reporter_sub_district"
                          :options="subDistricts"
                          label="name_in_thai"
                        ></v-select>
                      </div>
                    </b-col>
                    <b-col cols="12" lg="3">
                      <div class="form-group">
                        <label for="tel">หมู่บ้าน</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="reportInformation.reporter_village"
                        />
                      </div>
                    </b-col>
                  </b-row>
                </form>
                <div style="padding-bottom: 20px;" class="text-center">
                  <button
                    class="btn btn-m btn-primary  "
                    @click="onClickNext()"
                    v-if="step != 6"
                  >
                    <span>
                      ขั้นตอนต่อไป
                    </span>
                  </button>
                  <button
                    class="btn btn-m btn-primary  "
                    @click="onClickSave"
                    v-if="step == 6"
                  >
                    <span>
                      แจ้งข่าว
                    </span>
                  </button>
                </div>
                <div style="padding-bottom: 20px;" class="text-center">
                  <button
                    class="btn btn-s btn-whitebtn form-group"
                    style="border: 1px solid #000;"
                    @click="onClickBack()"
                    v-if="step != 1"
                  >
                    กลับ
                  </button>
                </div>
              </div>
            </b-card>

            <b-modal
              v-model="showModalComplete"
              id="doc-modal"
              size="xl"
              centered
              hide-footer
              hide-header
            >
              <!-- <div slot="modal-header">
                <h6 class="display-4 font-weight-bold">Search</h6>
                <p class="text-black-50 mb-0">
                  Use the form below to search for files
                </p>
              </div> -->
              <b-container>
                <b-row class="flex-sm-column-reverse flex-md-row">
                  <b-col
                    lg="12"
                    class="d-flex flex-column justify-content-between"
                  >
                    <p
                      class="
                        
                        text-center
                      "
                    >
                      ขอขอบคุณ<br />สำหรับการแจ้งเบาะแสของท่าน
                    </p>
                  </b-col>
                  <b-col lg="12">
                    <h5
                      class="display-5 mb-2 text-primary font-weight-bold text-center"
                    >
                      หมายเลขการแจ้งเบาะแสของท่าน
                    </h5>
                    <h3
                      class="display-5 mb-2 text-primary font-weight-bold text-center"
                    >
                      {{ requestNo }}
                    </h3>
                    <p class="text-center" style="font-size: 0.7rem;">
                      *กรุณาจดบันทึกหมายเลขหมายเลขการแจ้งเบาะแสหรือถ่ายรูปนี้ไว้
                      เพื่อใช้ในการตรวจสอบความคืบหน้า
                    </p>
                  </b-col>
                  <button
                    class="btn btn-lg btn-primary btn-block"
                    @click="completed()"
                  >
                    ตกลง
                  </button>
                </b-row>
              </b-container>
            </b-modal>

            <b-modal
              v-model="isShowConfirm"
              id="delete-modal"
              centered
              hide-header
              no-close-on-backdrop
            >
              <h3 class="font-weight-bold">ยืนยันการแจ้งเบาะแสใช่หรือไม่</h3>

              <template #modal-footer="{ cancel }">
                <b-button
                  size="sm"
                  variant="dark"
                  :disabled="isSubmiting"
                  @click="cancel()"
                >
                  ยกเลิก
                </b-button>
                <b-button size="sm" variant="primary" @click="onClickSend">
                  <b-spinner
                    v-if="isSubmiting"
                    small
                    label="submiting..."
                  ></b-spinner>
                  <span v-else>ยืนยัน</span>
                </b-button>
              </template>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <!-- <cookie-law theme="dark-lime"></cookie-law> -->
      <cookie-law theme="dark-lime">
        <div slot="message"></div>
        <div slot-scope="props">
          เว็บไซต์นี้มีการจัดเก็บคุกกี้เพื่อเพิ่มประสิทธิภาพในการใช้งานของท่าน
          และการมอบบริการที่ดีที่สุดจากเรา กรุณากดยอมรับ

          <button class="skew" @click="props.accept">
            <span>ยอมรับ</span>
          </button>
          หรือ
          <button class="skew" @click="props.close"><span>ปฏิเสธ</span></button>
        </div>
      </cookie-law>
    </footer>
  </div>
</template>

<script>
// import LogoSvg from "../../assets/img/logo.svg";
import vSelect from "vue-select";
// import Compressor from "compressorjs";
import { Auth } from "../../models";
import axios from "axios";
import CookieLaw from "vue-cookie-law";

export default {
  components: {
    // LogoSvg,
    "v-select": vSelect,
    CookieLaw,
  },

  data() {
    return {
      isShowConfirm: false,
      isSubmiting: false,
      marker: { position: { lat: 13.736717, lng: 100.523186 } },
      center: { lat: 13.736717, lng: 100.523186 },

      mapOptions: {
        disableDefaultUI: true,
      },
      showModalDoc: false,
      showModalOTP: false,
      checkingSignIn: false,
      checkingOTP: false,
      otpTimeout: 0,
      optInterval: null,
      form: {
        tel: "",
        authToken: "",
        token: "",
        user_profiles: null,
        otp_code: "",
      },
      step: 1,
      showModalComplete: false,
      isProcessing: false,
      validation: {
        type: Object,
      },
      invalid: {
        type: Boolean,
        default: false,
      },
      disabledInput: {
        type: Object,
        default: () => ({}),
      },
      width: {
        type: Number,
        default: 1920,
      },
      height: {
        type: Number,
        default: 1080,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      locations: [],
      suspects: [],
      reportInformation: {
        report_type: [],
        report_type_other: "",
        report_drug_other: "",
        locations: [],
        suspects: [],
        drugs: [],
        images: [],
        reporter_firstname: "",
        reporter_lastname: "",
        reporter_sex: "",
        reporter_address: "",
        reporter_tel: "",
        reporter_province: "",
        reporter_district: "",
        reporter_sub_district: "",
        reporter_village: "",
        attachedFiles: [],
        uploaedImage: [],
      },
      locatinos: [
        "คอนโดมิเนียม",
        "บ้าน",
        "บ้านเช่า",
        "พื้นที่รกร้าง",
        "ร้านขายยา",
        "ร้านค้า/ร้านอาหาร",
        "วัด/สุเหร่า/โบสถ์",
        "สถานที่ทำงาน",
        "สถานบันเทิง",
        "สถานศึกษา",
        "อะพาร์ตเมนต์",
        "อื่นๆ",
      ],
      provinces: [],
      districts: [],
      subDistricts: [],
      grovs: ["ใช่", "ไม่ใช่"],
      sex: ["ชาย", "หญิง"],
      skins: ["ดำ", "ดำแดง", "ขาว", "ขาวเหลือง", "เผือก"],
      jobs: ["อัยการ", "ครู", "พลเรือน", "อื่นๆ"],
      banks: ["ธนาคารไทยพานิชย์", "ธนาคารกสิกรไทย"],
      carTypes: ["ระกระบะ", "รถเก๋ง", "รถบรรทุก", "รถจักรยานยนต์", "อื่นๆ"],
      carBrands: ["โตโยต้า", "ฮอนด้า", "อีซูซุ", "เบนซ์", "อื่นๆ"],
      carColors: ["ดำ", "ขาว", "เงิน", "เทา", "อื่นๆ"],
      isShowDrugOther: false,
      requestNo: "",
    };
  },

  watch: {
    otpTimeout: "onOtpTimeoutChanged",
    "locations.province": function(val) {
      //to work with changes in someOtherProp
      console.log(val);
    },

    amphur: {
      handler: "onAmphurChanged",
    },
  },
  created() {
    // this.getMaster();
    this.initialData();
    this.getListProvince();
  },
  methods: {
    deleteAttached(index) {
      this.reportInformation.attachedFiles.splice(index, 1);
    },
    sameLocation(index) {
      this.suspects[
        index
      ].suspect_address_name = this.locations[0].location_name;
      this.suspects[
        index
      ].suspect_province = this.locations[0].location_province;
      this.suspects[
        index
      ].suspect_district = this.locations[0].location_district;
      this.suspects[
        index
      ].suspect_sub_district = this.locations[0].location_sub_district;
      this.suspects[index].suspect_village = this.locations[0].location_village;
    },
    async onClickSave() {
      this.isShowConfirm = true;
    },
    async onClickSend() {
      this.isSubmiting = true;
      console.log(this.reportInformation);
      this.reportInformation.locations = this.locations;
      this.reportInformation.suspects = this.suspects;
      this.reportInformation.uploaedImage = [];

      for (
        let index = 0;
        index < this.reportInformation.attachedFiles.length;
        index++
      ) {
        let element = this.reportInformation.attachedFiles[index];
        if (element.file) {
          const fileName = `${new Date().getTime()}_${element.file.name}`;

          var resGetUrl = await axios.get(
            // `https://thai-drug-service-temp.yuzudigital.com/reportForms/`,
            `https://e-learnning-service-temp.yuzudigital.com/teaching_dare/getURL?key=elearning_file/tests-images/${fileName}&contentType=${element.file.type}`,
            this.reportInformation
          );

          console.log(resGetUrl.data);

          const responseData = resGetUrl.data;

          if (responseData.url) {
            await axios.put(responseData.url, element.file);
            this.reportInformation.uploaedImage.push(responseData.fullUrl);
          }
        }
      }

      var res = await axios.post(
        `https://thai-drug-service-temp.yuzudigital.com/reportForms/`,
        // `http://localhost:8081/reportForms/`,
        this.reportInformation
      );

      console.log(res.data.data);
      if (res.data.response_status == "SUCCESS") {
        this.requestNo = res.data.data.reportNo;
      } else {
        this.$toast.error(res.data.message);
      }

      this.isSubmiting = false;

      this.showModalComplete = true;
    },

    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.marker.position = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        this.panToMarker();
      });
    },

    //sets the position of marker when dragged
    handleMarkerDrag(e, index) {
      console.log(index);
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    },

    //Moves the map view port to marker
    panToMarker() {
      this.$nextTick(() => {
        this.$refs.mapRef.panTo(this.marker.position);
        this.$refs.mapRef.setZoom(18);
      });
    },

    //Moves the marker to click position on the map
    handleMapClick(e, index) {
      console.log(index);
      this.locations[index].marker.position = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      };
      console.log(e);
    },
    async onChangeProvince(e) {
      console.log(e);
      console.log("changePro");

      // console.log(obj)
      // obj.location_district = ''
      // // console.log('ก่อนยิง จังหวัด', data)
      var res = await axios.get(
        `https://thai-drug-service-temp.yuzudigital.com/locations/findDistrictByProvinceId/${e.id}`
      );
      this.districts = res.data.data;
      console.log(this.districts);
    },
    async getListProvince() {
      var res = await axios.get(
        `https://thai-drug-service-temp.yuzudigital.com/locations/findAllProvinces`
      );
      this.provinces = res.data.data;
      // for (let index = 0; index < this.provinces.length; index++) {
      //   let provinces = this.provinces[index];
      //   this.provinces[index].key = provinces.id
      // }
      console.log("this.province", this.province);
    },
    async onChangeDistrict(e) {
      console.log(e);
      console.log("changePro");
      // // console.log('ก่อนยิง จังหวัด', data)
      var res = await axios.get(
        `https://thai-drug-service-temp.yuzudigital.com/locations/findSubDistrictByDistrictId/${e.id}`
      );
      this.subDistricts = res.data.data;
      console.log(this.subDistricts);
    },
    async onChangeSubDistrict(e, index) {
      console.log(e);
      console.log("changePro");
      this.locations[index].marker.position = {
        lat: e.latitude,
        lng: e.longitude,
      };
      this.locations[index].center = { lat: e.latitude, lng: e.longitude };
      // this.panToMarker();
    },
    onClickType(type) {
      if (this.reportInformation.report_type.includes(type)) {
        const index = this.reportInformation.report_type.indexOf(type);
        this.reportInformation.report_type.splice(index, 1);
      } else {
        this.reportInformation.report_type.push(type);
      }

      console.log(this.reportInformation.report_type);
    },
    onClickDrug(type) {
      if (this.reportInformation.drugs.includes(type)) {
        const index = this.reportInformation.drugs.indexOf(type);
        this.reportInformation.drugs.splice(index, 1);
      } else {
        this.reportInformation.drugs.push(type);
      }

      if (this.reportInformation.drugs.includes("อื่นๆ")) {
        this.isShowDrugOther = true;
      } else {
        this.isShowDrugOther = false;
      }

      console.log(this.reportInformation.drugs);
    },
    onClickDeleteTransport(suspectIndex, index) {
      this.suspects[suspectIndex].suspectTransports.splice(index, 1);
    },
    onClickAddTransport(suspectIndex) {
      this.suspects[suspectIndex].suspectTransports.push({
        transport_type: "",
        transport_brand: "",
        transport_color: "",
        transport_license_no: "",
        transport_license_province: "",
      });
    },
    onClickDeleteCard(suspectIndex, index) {
      this.suspects[suspectIndex].suspectCards.splice(index, 1);
    },
    onClickAddCard(suspectIndex) {
      this.suspects[suspectIndex].suspectCards.push({
        suspect_card_no: "",
        suspect_card_bank: "",
      });
    },
    onClickDeleteContact(suspectIndex, index) {
      this.suspects[suspectIndex].suspectContacts.splice(index, 1);
    },
    onClickAddContact(suspectIndex) {
      this.suspects[suspectIndex].suspectContacts.push({
        suspect_email: "",
        suspect_tel: "",
      });
    },
    onClickDeleteSuspect(index) {
      this.suspects.splice(index, 1);
    },
    onClickAddSuspect() {
      this.suspects.push({
        suspect_name: "",
        suspect_lastname: "",
        suspect_nickname: "",
        suspect_age: "",
        suspect_sex: "",
        suspect_is_grov: "",
        suspect_height: "",
        suspect_skin: "",
        suspect_tattoo: "",
        suspect_job: "",
        suspect_company: "",
        suspect_address_name: "",
        suspect_province: "",
        suspect_district: "",
        suspect_sub_district: "",
        suspect_village: "",
        suspect_is_in_area: true,
        suspect_detail: "",
        suspect_facebook: "",
        suspect_ig: "",
        suspect_line: "",
        suspect_twitter: "",
        suspect_tiktok: "",
        suspect_social_other: "",
        suspect_id_card: "",
        suspectContacts: [
          {
            suspect_email: "",
            suspect_tel: "",
          },
        ],
        suspectCards: [
          {
            suspect_card_no: "",
            suspect_card_bank: "",
          },
        ],
        suspectTransports: [
          {
            transport_type: "",
            transport_brand: "",
            transport_color: "",
            transport_license_no: "",
            transport_license_province: "",
          },
        ],
      });
    },
    onClickDeleteLocation(index) {
      this.locations.splice(index, 1);
    },
    onClickAddLocation() {
      this.locations.push({
        location_type: "",
        location_name: "",
        location_province: "",
        location_district: "",
        location_sub_district: "",
        location_village: "",
        marker: { position: { lat: 13.736717, lng: 100.523186 } },
        center: { lat: 13.736717, lng: 100.523186 },
      });
    },
    initialData() {
      this.locations.push({
        location_type: "",
        location_name: "",
        location_province: "",
        location_district: "",
        location_sub_district: "",
        location_village: "",
        marker: { position: { lat: 13.736717, lng: 100.523186 } },
        center: { lat: 13.736717, lng: 100.523186 },
      });

      this.suspects.push({
        suspect_name: "",
        suspect_lastname: "",
        suspect_nickname: "",
        suspect_age: "",
        suspect_sex: "",
        suspect_is_grov: "",
        suspect_height: "",
        suspect_skin: "",
        suspect_tattoo: "",
        suspect_job: "",
        suspect_company: "",
        suspect_address_name: "",
        suspect_province: "",
        suspect_district: "",
        suspect_sub_district: "",
        suspect_village: "",
        suspect_is_in_area: true,
        suspect_detail: "",
        suspect_facebook: "",
        suspect_ig: "",
        suspect_line: "",
        suspect_twitter: "",
        suspect_tiktok: "",
        suspect_social_other: "",
        suspect_id_card: "",
        suspectContacts: [
          {
            suspect_email: "",
            suspect_tel: "",
          },
        ],
        suspectCards: [
          {
            suspect_card_no: "",
            suspect_card_bank: "",
          },
        ],
        suspectTransports: [
          {
            transport_type: "",
            transport_brand: "",
            transport_color: "",
            transport_license_no: "",
            transport_license_province: "",
          },
        ],
      });
    },
    toggleAttachFile() {
      this.$nextTick(() => {
        this.$refs.bannerImg.click();
      });
    },
    async previewImage(event) {
      const self = this;
      // const { width, height } = this;

      var input = event.target;

      const maxAllowedSize = 10 * 1024 * 1024;

      Array.from(input.files)
        .slice(0, 3)
        .forEach((file) => {
          if (file.size <= maxAllowedSize) {
            // fileCounter++;

            self.reportInformation.attachedFiles.push({
              file: file,
              name: file.name,
            });

            self.isProcessing = false;
          } else {
            this.$toast.error(
              "ไม่สามารถอัพโหลดไฟล์ขนาดใหญ่เกิน 15 MB ได้ กรุณาลองใหม่อีกครั้ง"
            );
          }
        });

      input.value = "";
    },
    onClickNext() {
      if (this.step == 1) {
        if (this.reportInformation.report_type.length == 0) {
          this.$toast.error("กรุณาเลือก เลือกประเภทข้อมูล อย่างน้อย 1 ประเภท");
          return;
        }
      } else if (this.step == 2) {
        for (let index = 0; index < this.locations.length; index++) {
          const location = this.locations[index];
          if (
            location.location_type == "" ||
            location.location_name == "" ||
            location.location_province == "" ||
            location.location_district == "" ||
            location.location_sub_district == "" ||
            location.location_village == ""
          ) {
            this.$toast.error(
              "กรุณากรอกข้อมูลที่มีเครื่องหมาย ดอกจัน (*) ให้ครบถ้วน"
            );
            return;
          }
        }
        // if (this.locations.length == 0) {
        //   this.$toast.error("กรุณากรอกข้อมูล สถานที่ อย่างน้อย 1 ประเภท");
        //   return;
        // }
      } else if (this.step == 3) {
        for (let index = 0; index < this.suspects.length; index++) {
          const suspect = this.suspects[index];
          if (
            suspect.suspect_nickname == "" ||
            suspect.suspect_province == "" ||
            suspect.suspect_district == "" ||
            suspect.suspect_sub_district == ""
          ) {
            this.$toast.error(
              "กรุณากรอกข้อมูลที่มีเครื่องหมาย ดอกจัน (*) ให้ครบถ้วน"
            );
            return;
          }
        }
      }
      this.step++;
      window.scrollTo(0, 0);
    },
    onClickBack() {
      this.step--;
    },
    completed() {
      location.reload();
    },
    async signIn() {
      // this.checkingSignIn = true;

      // let { tel } = this.form;

      // tel = tel.replace(/-/g, "");

      // try {
      //   const { user_profiles, token: authToken } = await Auth.api().signIn(
      //     tel
      //   );

      //   this.$set(this.form, "authToken", authToken);
      //   this.$set(this.form, "user_profiles", user_profiles);

      //   const token = await Auth.api().getOTP(tel);

      //   this.$set(this.form, "token", token);

      //   this.setOtpTimeout();

      //   this.showModalOTP = true;
      // } catch (message) {
      //   this.$toast.error(message);
      // } finally {
      //   this.checkingSignIn = false;
      // }
      Auth.setAuthToken(
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OSwiZmlyc3RfbmFtZSI6IlRBTyIsImxhc3RfbmFtZSI6IlRBTyIsInBvc2l0aW9uX25hbWUiOiJBRE1JTiIsImlkX2NhcmQiOm51bGwsImlkX2dvdmVybm1lbiI6bnVsbCwiYmlydGhfZGF0ZSI6bnVsbCwicmV0aXJlX3llYXIiOm51bGwsImFkZHJlc3MiOm51bGwsImFkZHJlc3NfZG9jdW1lbnQiOm51bGwsInBob25lX251bWJlciI6IjA5OTA1Njk1OTUiLCJlbWFpbCI6bnVsbCwidXNlcl9uYW1lIjpudWxsLCJtb2RlbF9ubyI6bnVsbCwibW9kZWxfeWVhciI6bnVsbCwidXNlcl9zdGF0dXMiOiLguYDguILguYnguLLguKrguK3guJkiLCJ1c2VyX2FwcHJvdmUiOm51bGwsImNyZWF0ZV9ieSI6bnVsbCwidXBkYXRlX2J5IjpudWxsLCJwYXNzaGFzaCI6bnVsbCwidXNlcl90eXBlIjoiU1VQRVJfQURNSU4iLCJhZG1pbl90eXBlIjpudWxsLCJiZF9kYXRlIjpudWxsLCJ0b3RhbF90aW1lIjo5MSwibWVkYWwiOiLguKPguLDguJTguLHguJrguJXguYnguJkiLCJjcmVhdGVkQXQiOiIyMDIxLTEwLTE5VDAyOjM3OjMyLjk4NloiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTI4VDAzOjU4OjMzLjc2MVoiLCJ1c2VyR3JvdXBJZCI6bnVsbCwibVJhbmtJZCI6bnVsbCwibUhlYWRxdWFydGVySWQiOm51bGwsIm1EaXZpc2lvbklkIjpudWxsLCJtU3RhdGlvbklkIjpudWxsLCJpYXQiOjE2MzgxMjU2MTZ9.nw1yJMu9w_68B432P23STbiHba0tNriVrsw4VtfGBMU"
      );
      Auth.setAuthId(9);

      this.showModalOTP = false;

      this.$toast.success(
        "เข้าสู่ระบบสำเร็จ ระบบจะเข้าสู้หน้าหลักในอีกสักครู่"
      );

      setTimeout(() => {
        location.href =
          decodeURIComponent(this.$route.query.redirectUrl) || "/";
      }, 1000);
    },

    setOtpTimeout() {
      this.otpTimeout = 30;
    },

    onOtpTimeoutChanged(v) {
      if (v > 0 && !this.optInterval) {
        this.optInterval = setInterval(() => {
          this.otpTimeout -= 1;
        }, 1000);
      } else {
        if (v === 0) {
          clearInterval(this.optInterval);
          this.optInterval = null;
        }
      }
    },

    async validateOTP() {
      this.checkingOTP = true;
      try {
        if (!this.form.otp_code.length) {
          throw "กรุณากรอกรหัส OTP";
        }

        const valid = await Auth.api().validateOTP(
          this.form.token,
          this.form.otp_code
        );

        if (valid) {
          Auth.setAuthToken(this.form.authToken);
          Auth.setAuthId(this.form.user_profiles.id);

          this.showModalOTP = false;

          this.$toast.success(
            "เข้าสู่ระบบสำเร็จ ระบบจะเข้าสู้หน้าหลักในอีกสักครู่"
          );

          setTimeout(() => {
            location.href =
              decodeURIComponent(this.$route.query.redirectUrl) || "/";
          }, 1000);
        }
      } catch (message) {
        console.log(message);
        this.$toast.error(message);
      } finally {
        this.checkingOTP = false;
      }
    },
  },
};
</script>
